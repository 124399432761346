import Constants from './AppConstants';
import ReactGA from 'react-ga';

export const InitializeGA = () => {
  ReactGA.initialize(Constants.GA_TRACKING_ID); // put your tracking id here
}

export const TrackPageViewGA = (page) => {
  ReactGA.pageview(page);
  console.log("page tracking sent to GA for " + page);
}

export const TrackEventGA = (categoryName, eventName) => {
  ReactGA.event({       
      category: categoryName,  // Required
      action: eventName,       // Required
      label: 'labelName',       
      value: 10,       
      nonInteraction: false     
  });   
}