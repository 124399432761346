import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { logout } from '../services/auth.service';
import { Redirect } from 'react-router-dom';
import cookie from 'js-cookie';
import AggregateSummary from './AggregateSummary';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      isChangePasswordVisible: false
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  handleLogout(event) {
    event.preventDefault();
    try {
      logout();
      this.setState({ isLoggedIn: false });
    }
    catch (error) {
      console.error('Logout failed.', error)
    }
  }

  render() {
    // const isAggregateUser = cookie.get('aggregate');
    const user = cookie.get('username');
    const hospitalName = cookie.get('fhiName')
    const stateName = cookie.get('state');
    const regionName = cookie.get('region');
    const hierarchyLevel = cookie.get('hierarchyLevel');
    if (!this.state.isLoggedIn) {
      return <Redirect to='/login' />
    }
    
    return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt => evt.preventDefault()}><img src={require("../../assets/images/hnb-logo-box.png")} height="40" width="40" alt="logo" /></a>
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
            <i className="mdi mdi-menu"></i>
          </button>
          <ul className="navbar-nav navbar-nav-right ml-lg-auto">
            <li className="nav-item  nav-profile border-0 pl-4">
              {hierarchyLevel === 'HOSPITAL' ? <p className="pt-3"><i className="mdi mdi-home"></i>{hospitalName}</p> : hierarchyLevel === 'STATE' ? <p className="pt-3"><i className="mdi mdi-home"></i>{stateName}</p> : hierarchyLevel === 'REGION' ? <p className="pt-3"><i className="mdi mdi-home"></i>{regionName}</p> : <AggregateSummary />}
              {/* {isAggregateUser !== 'true' ? <p className="pt-3"><i className="mdi mdi-home"></i>{hospitalName}</p> : <AggregateSummary />} */}
            </li>
            <li className="nav-item  nav-profile border-0">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <span className="profile-text">{user}</span>
                  <img className="img-xs rounded-circle" src={require("../../assets/images/avatar.png")} alt="Profile" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" href="/change-password">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.handleLogout}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
