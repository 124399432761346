import React from 'react';
import Constants from '../AppConstants';

class AggregateSummary extends React.Component {
    abortController = new AbortController()
    constructor(props) {
        super(props);
        this.state = {
            fth: 0,
            fmc: 0,
            sh: 0,
            sumTotal: 0
        };
    }

    componentDidMount() {
        fetch(Constants.BASE_URL + 'aggregatesummary', {signal: this.abortController.signal})
            .then(res => res.json())
            .then((formattedData) => {
                let data = JSON.parse(formattedData);
                let fmcVal = data.FMC ? data.FMC : 0;
                let fthVal = data.FTH ? data.FTH : 0;
                let shVal = data.SH ? data.SH : 0;
                let total = fmcVal + fthVal + shVal
                this.setState({
                    fth: fthVal,
                    fmc: fmcVal,
                    sh: shVal,
                    sumTotal: total
                });
            })
            .catch(console.log)
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    render() {
        return (
            <div className="row">
                <div className="col-12 p-0">
                    <div className="row pt-2 border border-success mr-2" style={{ borderRadius: "5px" }}>
                        <div className="col-2 pr-0 text-right">
                            <h1>{this.state.sumTotal}</h1>
                        </div>
                        <div className="col-10">
                            <div className="row">
                                <div className="col-12 pt-2">
                                    <small className="font-weight-bold"> Connected FHIs</small>
                                </div>
                                <div className="col-12 my-0 py-0 fhi-breakdown">
                                    <div className="container-fluid my-0 py-0">
                                        <div className="row my-0 py-0">
                                            <div className="col my-0 p-0"><small>{this.state.fth} FTHs</small></div>
                                            <div className="col my-0 p-0"><small>{this.state.fmc} FMCs</small></div>
                                            <div className="col my-0 p-0"><small>{this.state.sh} SHs</small></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export default AggregateSummary;